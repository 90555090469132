import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { doc, getDoc } from "firebase/firestore";
import { IJournal } from "../../interfaces/interfaces";
import { auth, db } from "../../services/firebase";

const fetchJournal = async (journalId: string): Promise<IJournal | null> => {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error('User not authenticated');
  }

  const journalDocRef = doc(db, `journals/${journalId}`);
  const journalDoc = await getDoc(journalDocRef);

  if (journalDoc.exists()) {
    return { id: journalDoc.id, ...journalDoc.data() } as IJournal;
  } else {
    throw new Error('Journal does not exist');
  }
};


export const useJournal = (journalId: string): UseQueryResult<IJournal | null, Error> => {
  return useQuery<IJournal | null, Error>({
    queryKey: ['journal', journalId],
    queryFn: () => fetchJournal(journalId),
  });
};