import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { initializeFirestore } from 'firebase/firestore'

import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getStorage } from 'firebase/storage';

import {firebaseConfig} from './firebaseConfig';

const app = initializeApp(firebaseConfig);

const db = initializeFirestore(app, { experimentalForceLongPolling: true });

const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app);

if (location.hostname === 'localhost') {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectDatabaseEmulator(database, 'localhost', 9000);
}

export { app, db, auth, database, storage };
