import { useMutation, useQueryClient, UseMutationResult } from "@tanstack/react-query";
import { getFirestore, collection, addDoc, DocumentReference, DocumentData } from "firebase/firestore";
import { auth, db } from "../../services/firebase";

const createJournal = async (email: string): Promise<DocumentReference<DocumentData>> => {
  const user = auth.currentUser;

  if (!user) {
    throw new Error("User not authenticated");
  }

  const user1 = {
    email: user.email,
    uid: user.uid,
    displayName: user.displayName,
    photoURL: user.photoURL,
  };
  const user2 = {
    email: email.toLowerCase(),
  };

  const journalRef = await addDoc(collection(db, "journals"), {
    creationDate: new Date(),
    users: [user1, user2],
    allowedUsers: [user1.uid, user1.email, user2.email],
  });

  return journalRef;
};

export const useCreateJournal = (): UseMutationResult<DocumentReference<DocumentData>, Error, string> => {
  const queryClient = useQueryClient();
  return useMutation<DocumentReference<DocumentData>, Error, string>({
    mutationFn: createJournal,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['journals'] });
    },
  });
};
