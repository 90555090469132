import { useMutation, useQueryClient, UseMutationResult } from "@tanstack/react-query";
import { getFirestore, collection, addDoc, DocumentReference, DocumentData } from "firebase/firestore";
import { IEntry } from "../../interfaces/interfaces";

const createEntry = async (newEntry: IEntry): Promise<DocumentReference<DocumentData>> => {
  const db = getFirestore();
  const entriesRef = collection(db, `journals/${newEntry.journalId}/entries`);
  const docRef = await addDoc(entriesRef, newEntry);
  return docRef;
};

export const useCreateEntry = (): UseMutationResult<DocumentReference<DocumentData>, Error, IEntry> => {
  const queryClient = useQueryClient();
  return useMutation<DocumentReference<DocumentData>, Error, IEntry>({
    mutationFn: createEntry,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['entries'] });
    },
  });
};