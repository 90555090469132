
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Button, Grid, Icon, IconButton, Typography } from "@mui/material";
interface NavButtonProps {
    text?: string;
    isBreadCrumb?: boolean
    pageTitle?: string
}
export default function NavButton({ text, isBreadCrumb, pageTitle }: NavButtonProps) {
    const navigate = useNavigate();
    return ( <>
        <Grid container alignItems="center" spacing={2}>
            <Grid item>
                <Button
                    style={isBreadCrumb ? { marginTop: "10px", display: "flex" } : {}}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => navigate('/journals/all')}
                >
                    <div>
                        <Icon style={{ color: "#000" }}>
                            <ArrowBackIcon />
                        </Icon>
                    </div>
                    <div>{text || "Back to all journals"}</div>
                </Button>
            </Grid>
            <Grid item>
                <Typography variant="h6">
                    {pageTitle}
                </Typography>
            </Grid>
        </Grid>

        {isBreadCrumb &&
            <hr style={{ background: "rgb(100 102 128)", width: "100%", height: "4px" }}></hr>
        }
    </>)
}