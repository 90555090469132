import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, signOut, onAuthStateChanged, GoogleAuthProvider, User } from "firebase/auth";
import { Button } from '@mui/material';

function Login() {
  const [user, setUser] = useState<User | null>(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  const handleSignIn = () => {
    const googleAuthProvider = new GoogleAuthProvider();
    signInWithPopup(auth, googleAuthProvider).catch(error => {
      console.error("Error during sign-in:", error);
    });
  };

  const handleSignOut = () => {
    signOut(auth).catch(error => {
      console.error("Error during sign-out:", error);
    });
  };

  return (
    <div className="login text-lg">
      {!user ? (
        <button className='text-lg !text-white font-light' onClick={handleSignIn}>
          SIGN IN
        </button>
      ) : (
        <button className='text-lg !text-white font-light' onClick={handleSignOut}>
          SIGN OUT
        </button>
      )}
    </div>
  );
}

export default Login;
