import { useMutation, useQueryClient, UseMutationResult } from "@tanstack/react-query";
import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";
import { IUser } from "../../interfaces/interfaces";
import { auth } from "../../services/firebase";


// this really should be called acceptJournalInvite
const updateJournal = async ({ journalId }: { journalId: string }): Promise<void> => {

  const currentUser = auth.currentUser;

  const newUser = {
    email: currentUser?.email,
    uid: currentUser?.uid,
    displayName: currentUser?.displayName,
    photoURL: currentUser?.photoURL,
  };

  const db = getFirestore();
  const journalDocRef = doc(db, "journals", journalId);
  const journalDoc = await getDoc(journalDocRef);

  if (!journalDoc.exists()) {
    throw new Error("Journal not found");
  }

  const journalData = journalDoc.data();

  // find the user in the existing users array that is the current user, and update it so it has all fields not just email
  const updatedUsers: IUser[] = journalData?.users.map((user: IUser) => {
    if (user.email === currentUser?.email) {
      return { ...user, ...newUser };
    }
    return user;
  });

  await updateDoc(journalDocRef, {
    ...journalData,
    users: updatedUsers,
    allowedUsers: [...updatedUsers.map(user => user.uid), ...updatedUsers.map(user => user.email)],
  });
};

export const useUpdateJournal = (): UseMutationResult<void, Error, { journalId: string }> => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, { journalId: string }>({
    mutationFn: updateJournal,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['journals'] });
    },
  });
};  
