export const firebaseConfig = {
    apiKey: "AIzaSyBqD4rISAawVmJKwN51DCRDNqEz4DzApzc",
    authDomain: "journal-swap-2ec4b.firebaseapp.com",
    projectId: "journal-swap-2ec4b",
    storageBucket: "journal-swap-2ec4b.appspot.com",
    messagingSenderId: "724134308860",
    appId: "1:724134308860:web:4e1b0e6628853e9da2e079",
    measurementId: "G-LY07HTXQVB",
    ...(location.hostname === 'localhost' ? {databaseURL: 'http://localhost:9000?ns=emulatorui'} : {}),
  };
