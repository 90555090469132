import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#eeffff",
      main: "#bbdefb",
      dark: "#8aacc8",
      contrastText: "#000",
    },
    secondary: {
      light: "#ffc4ff",
      main: "#ce93d8",
      dark: "#9c64a6",
      contrastText: "#000",
    },
  },
});

export default theme;
