import { Grid, Typography, Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import Entry from "./entry";
import { useDispatch, useSelector } from "react-redux";

import { IUser, IEntry, IJournal } from '../../interfaces/interfaces'
import NavButton from "../NavButton";
import { useJournal } from "../../api/journals/useJournal";
import { useCreateEntry } from "../../api/entries/useCreateEntry";
import { useEntries } from "../../api/entries/useEntries";
import { useParams } from "react-router-dom";
import { auth } from "../../services/firebase";


function Journal() {

  const defaultFont = "semisweet";

  const { journalId } = useParams();

  const initialLimit = 5;

  const [limit, setLimit] = useState(initialLimit);

  const { data: journal, error: errorJournal, isLoading: isLoadingJournal } = useJournal(journalId || "no-id");
  const createEntryMutation = useCreateEntry();
  const { data: entries, error: errorEntries, isLoading: isLoadingEntries } = useEntries(journalId || "no-id");

  if (isLoadingJournal) return <div>Loading...</div>;
  if (errorJournal) return <div>Error loading Journal: {errorJournal.message}</div>;

  const sharedUsers = journal && journal?.users && journal?.users.map((user: IUser) => " " + user.email)

  const questionOfTheDay = [
    "What is your favorite memory from highschool?",
    "What is your favorite childhood memory?",
    "Describe a time when you felt truly happy.",
    "What is a lesson you learned the hard way?",
    "Write about a time you overcame a significant challenge.",
    "What was a pivotal moment in your life?",
    "Who has had the most impact on your life and why?",
    "What is a decision you made that changed the course of your life?",
    "Describe a memorable vacation or trip you took.",
    "What was your favorite subject in school and why?",
    "Write about a time you felt proud of yourself.",
    "What is a skill you learned that you are grateful for?",
    "Describe a time when you helped someone in need.",
    "What was the best advice you ever received?",
    "Write about a memorable holiday celebration.",
    "What is a tradition from your family that you cherish?",
  ]

  console.log("add pagination to this page")

  const currentUser = auth.currentUser;
  return (
    <>
      <NavButton isBreadCrumb pageTitle={"Shared journal with " + sharedUsers} />

      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={6}>
          <Button
            onClick={() => {
              createEntryMutation.mutate({
                font: defaultFont,
                updatedDate: new Date().toDateString(),
                journalId: journalId,
                owner: currentUser?.uid
              } as IEntry)
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Start new entry
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            Question of the day:
            {questionOfTheDay[0]}
          </Typography>
          <Button
            onClick={() => {
              createEntryMutation.mutate({
                font: defaultFont,
                updatedDate: new Date().toDateString(),
                journalId: journalId,
                prompt: questionOfTheDay[0] + "\n",
                owner: currentUser?.uid
              } as IEntry)
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Respond to the question of the day
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {entries && entries.length > 0 ? (
              entries.map((entry, index) => {
                return (
                  <div
                    key={entry.id ? entry.id : index}
                  >
                    <Entry
                      // should remove a lot of these and just pass entryID and let the entry get the details from the store
                      // ref={index == 0 ? mostRecentEntryRef : null}
                      entry={entry}
                      text={entry.text}
                      date={new Date().toDateString()}
                      font={entry.font}
                      creator={entry.creator}
                      journalId={entry.journalId}
                      initialColor={entry.color}
                    />
                  </div>

                );
              })
            ) : (
              <div></div>
            )}
          </Paper>
        </Grid>

        {/* <Grid item xs={12}>

          {entries && <><Button
            onClick={handleGetMoreEntries}
            variant="contained"
            color="primary"
            disabled={isDisabled()}
            size="small"
            style={{ marginRight: "10px" }}
          >
            {isDisabled() ? "No more entries to fetch" : `Fetch ${limit} more entries`}
          </Button>
            Showing {entries.length} latest entries...
          </>}
        </Grid> */}
      </Grid>


    </>
  );
}

export default Journal;
