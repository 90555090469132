import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface NavButtonProps {
    text?: string;
    isBreadCrumb?: boolean;
    pageTitle?: string;
}

export default function NavButton({ text, isBreadCrumb, pageTitle }: NavButtonProps) {
    const navigate = useNavigate();
    return (
        <>
            <div className={`flex items-center justify-center space-x-2 ${isBreadCrumb ? 'mt-2' : ''}`}>
                <button
                    className="flex p-8 bg-tertiary text-coffee font-bold text-lg rounded-full"
                    onClick={() => navigate('/journals/all')}
                >
                    <span className="ml-2">{text || "Back to all journals"}</span>
                </button>
                <h6 className="text-lg font-semibold">{pageTitle}</h6>
            </div>
            {isBreadCrumb && <hr className="w-full h-1 mt-2" />}
        </>
    );
}
