import { Typography, Button } from "@mui/material";

import React, { useEffect } from "react";


import { useInvites } from "../../api/invites/useInvites";
import { useDeleteInvite } from "../../api/invites/useDeleteInvite";
import { IInvite, IUser } from "../../interfaces/interfaces";
import { useUpdateJournal } from "../../api/journals/useUpdateJournal";
import { auth } from "../../services/firebase";

//This is the page that a user sees after they accept a journal?



function JoinJournal() {

  const currentUser = auth.currentUser;

  const { data: invites, isLoading: isLoadingInvites, error: errorInvites } = useInvites()
  const deleteInviteMutation = useDeleteInvite();
  const updateJournal = useUpdateJournal()

  if (isLoadingInvites) return <div>Loading...</div>;
  if (errorInvites) return <div>Error loading Invites: {errorInvites.message}</div>;

  const handleAcceptInvite = (invite: IInvite) => {
    // join the journal
    if (currentUser == null) return;
    
    updateJournal.mutate({ journalId: invite.journalId })
    //delete the invite
    deleteInviteMutation.mutate(invite.id)
  }

  const handleCancelInvite = (invite: IInvite) => {
    // delete the invite
    deleteInviteMutation.mutate(invite.id)
  }

  return (
    <>
      <div >
        {invites && invites.length > 0 ? (
          invites.map((invite) => {
            const filteredUsers = invite.users.filter(user => user.email !== currentUser?.email);
            return (
              <div>
                <Typography variant="h6">Journal invites:</Typography>

                <Typography variant="body1">
                  {invite.users[0].displayName}
                </Typography>

                {invite.invitee.toLowerCase() == currentUser?.email?.toLowerCase() ?
                  <>
                    <Button
                      onClick={() => deleteInviteMutation.mutate(invite.id)}
                      variant="contained"
                      color="secondary"
                      style={{ marginRight: "10px" }}
                    >
                      Decline invitation
                    </Button>
                    <Button
                      onClick={() => handleAcceptInvite(invite)}
                      variant="contained"
                      color="primary"
                    >
                      Accept invitation from {invite.users[0].displayName} (
                      {invite.users[0].email})
                    </Button>
                  </>
                  :
                  <Button
                    onClick={() => handleCancelInvite(invite)}
                    variant="contained"
                    color="primary"
                  >
                    Cancel invitation to {filteredUsers.map((user) => user.email)} ({filteredUsers.map((user) => user.email)})
                  </Button>}
              </div>
            );
          })
        ) : (
          <Typography variant="body1">No new invitations.</Typography>
        )}
      </div>
    </>
  );
}

export default JoinJournal;
