import { useMutation, useQueryClient, UseMutationResult } from "@tanstack/react-query";
import { collection, addDoc, DocumentReference, DocumentData } from "firebase/firestore";
import { IInvite, IUser } from "../../interfaces/interfaces";
import { auth, db } from "../../services/firebase";

interface IInvitePayload {
  email: string;
  journalId: string;
}
const createInvite = async ({email, journalId}: IInvitePayload): Promise<DocumentReference<DocumentData>> => {
  const user = auth.currentUser;

  if (!user) {
    throw new Error("User not authenticated");
  }

  const user1 = {
    email: user.email,
    uid: user.uid,
    displayName: user.displayName,
    photoURL: user.photoURL,
  };

  // user2 hasn't accepted, and may not be in the db yet. 
  const user2 = {
    email: email.toLowerCase(),
  };

  const inviteRef = await addDoc(collection(db, "invites"), {
    invitee: user2.email,
    invitor: user1,
    users: [user1, user2],
    journalId: journalId,
  });

  return inviteRef;

};

export const useCreateInvite = (): UseMutationResult<DocumentReference<DocumentData>, Error, IInvitePayload> => {
  const queryClient = useQueryClient();
  return useMutation<DocumentReference<DocumentData>, Error, IInvitePayload>({
    mutationFn: createInvite,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invites'] });
    },
  });
};
