import { useState, useRef } from "react";
import * as React from "react";
import debounce from "lodash.debounce";
import PopoverPopupState from "../colorPopover";
import TextareaAutosize from "react-textarea-autosize";
import { IEntry } from "../../interfaces/interfaces";
import CloseIcon from "@mui/icons-material/Close";
import FontDownload from "@mui/icons-material/FontDownload";
import { Grid, Typography, Button, MenuItem, Menu } from "@mui/material";

import { FONTS } from "../../constants"
import { useDeleteEntry } from "../../api/entries/useDeleteEntry";
import { auth } from "../../services/firebase";
import { useUpdateEntry } from "../../api/entries/useUpdateEntry";

interface EntryProps {
  entry: IEntry;
  text: string;
  date?: string;
  font: string;
  creator?: string;
  journalId: string;
  initialColor?: string;
  disabled?: boolean;
}

function Entry({
  entry,
  text,
  date,
  font,
  creator,
  journalId,
  initialColor,
  disabled,
}: EntryProps): JSX.Element {

  const [multiline, setMultiline] = useState(text);
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const [color, setColor] = useState(initialColor || "#111");
  const currentUser = auth.currentUser;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);


  const deleteEntryMutation = useDeleteEntry();
  const updateEntryMutation = useUpdateEntry();


  const debouncedSave = useRef(
    debounce(async (value: IEntry) => {
      updateEntryMutation.mutate(value)
      setSaved(true);
      setSaving(false);
    }, 2000)
  ).current;

  const handleMultiLineChange = (value: string) => {
    const saveObjWithColor = { text: value, color: color, font: font, id: entry.id };
    setMultiline(value);
    setSaved(false);
    setSaving(true);
    debouncedSave({ ...saveObjWithColor } as IEntry);
  };

  const handleColorChange = (value: string) => {
    const saveObjWithText = { text: multiline, color: value, font: font, id: entry.id };
    setColor(value);
    setSaved(false);
    setSaving(true);
    debouncedSave({ ...saveObjWithText } as IEntry);
  };

  const handleFontChange = (value: string) => {
    const saveObjWithText = { text: multiline, color: color, font: value, id: entry.id };
    const fontClass = `font-face-${value}`
    setEntryFont(fontClass)
    setSaved(false);
    setSaving(true);
    debouncedSave({ ...saveObjWithText } as IEntry);
    handleClose()
  };

  const [entryFont, setEntryFont] = useState(`font-face-${entry.font}`)

  React.useEffect(() => {
    if (!FONTS.includes(entry.font)) {
      console.warn(`WARNING: font "${entry.font}" not found in FONTS array: ${FONTS}`)
    }
  }, [entry.font])

  const handleOnDelete = () => {
    confirm("Are you sure you want to delete this entry? This cannot be undone.") ? deleteEntryMutation.mutate(entry) : null
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <Grid className="" container justifyContent="end" alignItems="center" sx={{ marginLeft: 2 }}>
        <Grid item xs={1}>
          <Typography variant="subtitle2">
            {saving && "Saving..."}
            {saved && "Saved!"}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <PopoverPopupState
            color={color}
            handleColorChange={handleColorChange}
          />
        </Grid>
        <Grid item xs={1}>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <FontDownload />
          </Button>
          <Menu
            id="font-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'font-menu-button',
            }}
          >
            {FONTS.map((font) =>
              <MenuItem key={font} onClick={() => handleFontChange(font)}>{font}</MenuItem>
            )}
          </Menu>
        </Grid>
        <Grid item xs={1}>
          {(entry.owner === currentUser?.uid || saved) &&
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleOnDelete}
            >
              <CloseIcon />
            </Button>}
        </Grid>
      </Grid>
      <Grid className="" container justifyContent="space-between" alignItems="center">
        <Grid item xs={6} container direction="row" justifyContent="flex-start" sx={{ marginLeft: 2 }}>
          <Grid item xs={4}>
            <Typography className={entryFont} align="left">
              {creator || currentUser?.displayName || "Anonymous User"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={entryFont} align="left">
              Date: {date}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} sx={{ marginLeft: 2 }}>
          <Typography align="left" variant="body1">{entry.prompt && `Question of day: ${entry.prompt}`}</Typography>

        </Grid>
        <Grid item xs={12} md={12}>
          <div className="textAreaBackground">
            {/* add this later for the most recent entry  */}
            {/* <TextareaAutosize autoFocus  */}
            <TextareaAutosize
              className={`textAreaAutoSize ${entryFont}`}
              style={{ color: color }}
              value={multiline}
              onChange={(saved || entry.owner == undefined || entry.owner === currentUser?.uid) ? (e) => handleMultiLineChange(e.target.value) : () => window.alert("you cannot edit this entry because it was not created by you")
              }
              disabled={disabled}
            ></TextareaAutosize>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Entry;
