import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Journal from "../components/journal/journal";
import { IJournal, IUser } from "../interfaces/interfaces";
import { getAuth } from "firebase/auth";
import theme from "../components/theme";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import { useJournal } from "../api/journals/useJournal";
import { useInvites } from "../api/invites/useInvites";
import { useJournals } from "../api/journals/useJournals";

function Journals() {
  const navigate = useNavigate();
  const { journalId } = useParams();

  const auth = getAuth();
  const currentUser = auth.currentUser;

  const { data: journals, isLoading: isLoadingJournals, error: errorJournals } = useJournals();
  const { data: invites, isLoading: isLoadingInvites, error: errorInvites } = useInvites();

  const determineJournalDetails = (journal: IJournal) => {
    const journalUser = journal?.users
      .filter((journalUser: IUser) => journalUser.uid !== currentUser?.uid)
      .map((user: IUser) => user.displayName);
    return "Journal with " + journalUser[0];
  };

  useEffect(() => {
    console.log("journals", journals);
  }, [journals])

  if (isLoadingInvites) return <div>Loading...</div>;
  if (errorInvites) return <div>Error loading Invites: {errorInvites.message}</div>;

  return (
    <div className="content">
      <Grid container spacing={3} style={{ minWidth: '400px' }}>
        <Grid item xs={12}>
          <Card variant="outlined" sx={{ height: '100%', minWidth: 200, minHeight: 100 }}>
            <CardContent>
              <Typography gutterBottom>
                {`You have ${invites?.length || "no"} new invite${invites && invites?.length > 1 ? 's' : ''}.`}
              </Typography>
            </CardContent>
            <CardActions>
              <Badge color="secondary" badgeContent={invites?.length || ""}
                sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => navigate('/journals/join')}
                >
                  Invite
                  <AddIcon />
                </Button>
              </Badge>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined" sx={{ height: '100%', minWidth: 200, minHeight: 100 }}>
            <CardContent>
              <Typography gutterBottom>Create a new journal</Typography>
            </CardContent>
            <CardActions>
              <IconButton
                sx={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  color: '#000',
                  backgroundColor: '#3f51b5',
                }}
                onClick={() => navigate('/journals/join')}
              >
                <AddIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>

        {journals?.map((journal: IJournal) => (
          <Grid item xs={12} sm={6} md={3} key={journal.id}>
            <Card variant="outlined" sx={{ height: '100%', minWidth: 200, minHeight: 100 }}>
              <CardContent>
                <Typography color="textPrimary" gutterBottom>
                  {determineJournalDetails(journal)}
                </Typography>
                <Typography color="textPrimary" gutterBottom>
                  Details:
                </Typography>
                <Typography variant="body2">
                  Journal Users:
                  <br />
                  {journal?.users.map((user: IUser) => (
                    <div key={user.uid}>{user.displayName}</div>
                  ))}
                </Typography>
                <Typography variant="body2">
                  Created:{' '}
                  {new Date(journal.creationDate.seconds * 1000).toLocaleDateString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => navigate(`/journals/${journal.id}`)}
                  sx={{ marginLeft: 'auto', marginRight: 'auto' }}
                >
                  Open Journal<CreateIcon />
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}

        {journals?.length === 0 && <Typography>There are no journals to show.</Typography>}
      </Grid>
    </div>
  );
}

export default Journals;
