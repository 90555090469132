import React from "react";

import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

import { HexColorPicker } from "react-colorful";
import ColorLens from "@mui/icons-material/ColorLens";

import { IconButton, Popover } from "@mui/material";

interface PopoverPopupStateProps{
  color: string;
  handleColorChange: (color: string) => void;

}
export default function PopoverPopupState({ color, handleColorChange }: PopoverPopupStateProps) {
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <IconButton
            color="primary"
            component="span"
            {...bindTrigger(popupState)}
          >
            <ColorLens fontSize="small" style={{ color: "#000", borderColor: color, borderRadius: "4px", borderWidth: "3px",  borderStyle: "solid" }} />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <HexColorPicker
              color={color}
              onChange={(value) => {
                handleColorChange(value);
              }}
            />
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
