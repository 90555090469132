import { Typography, Button, TextField } from "@mui/material";

import React, { useState } from "react";
import * as EmailValidator from "email-validator";
import { useDispatch } from "react-redux";
import NavButton from "../NavButton";
import { useCreateEntry } from "../../api/entries/useCreateEntry";
import { IEntry } from "../../interfaces/interfaces";
import { useCreateInvite } from "../../api/invites/useCreateInvite";
import { useCreateJournal } from "../../api/journals/useCreateJournal";
import { useSendEmail } from "../../api/useSendEmail";
import { auth } from "../../services/firebase";



export default function NewJournal() {

  const [email, setEmail] = useState("");
  const [error, setError] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const createJournalMutation = useCreateJournal();
  const createInviteMutation = useCreateInvite();
  const sendEmailMutation = useSendEmail();

  const currentUser = auth.currentUser;

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setError(!EmailValidator.validate(email));
  }

  const handleCreateNewJournal = async () => {
    console.log(
      "this should create a new journal, validate the email address, and send an invite link to the email"
    );
    setDisabled(true);
    try {
      const journalRef = await createJournalMutation.mutateAsync(email);
      await createInviteMutation.mutateAsync({ email, journalId: journalRef.id });
      await sendEmailMutation.mutateAsync(email);
    } catch (error) {
      console.error("Error creating journal and sending invite:", error);
    }
    setDisabled(false);
  };

  return (
    <>
      <div className="content">
        <NavButton isBreadCrumb />

        <Typography variant="h6">New Journal</Typography>
        <Typography paragraph={true} variant="body1">Enter the email of the user you want to invite to share a journal with you. They will receive an email with their invite link!</Typography>
        <div>
          <TextField
            error={error}
            id="standard-error-helper-text"
            label="Email address"
            helperText={error ? "Invalid email address" : ""}
            value={email}
            onChange={(e) => {
              handleEmailChange(e.target.value);
            }}
            onSelect={(e: any) => {
              handleEmailChange(e.target.value);
            }}
            placeholder={"ex: example@gmail.com"}
          />
        </div>
        <Button
          disabled={error || disabled}
          onClick={() =>
            handleCreateNewJournal()
          }
          variant="contained"
          color="primary"
        >
          Send invite link
        </Button>

      </div>
    </>
  );
}

