import "./App.scss";

// Firebase
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { useEffect, useState } from "react";

// React
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";

import theme from "./components/theme";
import './index.css'; // Add this line to import Tailwind CSS

import Home from "./pages/home";
import Journals from "./pages/journals";
import Invite from "./pages/invite";
import NavWithSideBar from "./components/NavWithSideBar";

// Firebase initialization (assumed to be in a separate file)
import "./services/firebase"; // This should initialize Firebase
import Journal from "./components/journal/journal";

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
        <ThemeProvider theme={theme}>
          <Router>
            {(user) ? (
              <>
                <NavWithSideBar />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/journals/join" element={<Invite />} />
                  <Route path="/journals/all" element={<Journals />} />
                  <Route path="/journals/:journalId" element={<Journal />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </>
            ) : (
              <>
                <NavWithSideBar linksDisabled />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
              </>
            )}
          </Router>
        </ThemeProvider>
    </div>
  );
}

export default App;
