import React from "react";
import Book from "../components/BookAnimation";
import RoundedNavButton from "../components/RoundedNavButton";
import { getAuth } from "firebase/auth";

const intro = "An easy place to connect with family through shared journals.";
const origin = "Hi, my name is Nick. I created this app in 2022 so that I could share my thoughts and experiences with my dad. I hope you enjoy using it as much as I do.";
const howItWorksArr = ["Invite a Family Member: They will receive an email with an invite request. ",
  "Create Entries: Members can create as many entries as they like. Lifescriber has daily and featured questions to ask to help them get started.",
  "Publish: As they publish entries, you will get access to view their published entries.",
  "Access: You will get access to view their published entries."]


export default function Home() {
  const currentUser = getAuth().currentUser;
  return (
    <div className="mx-auto">
      <section className="bg-secondary text-coffee px-4 min-h-64 py-4">
        <div className="bg-secondary rounded-xl p-8 max-w-4xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Hi! Welcome to Life Scriber</h2>
          <p className="text-lg md:text-2xl pb-8">{intro}</p>
          <RoundedNavButton text={currentUser ? "Make A New Journal" : "Make A New Journal"} />
        </div>
      </section>
      <section className="bg-rice text-coffee px-4 min-h-64 py-8">
        <div className="p-8 max-w-4xl mx-auto">
          <h2 className="text-left text-3xl md:text-4xl font-bold mb-4">How It Works</h2>
          <ol className="text-left ml-8 list-decimal">
            {howItWorksArr.map((item, index) => (
              <li key={index} className="text-lg md:text-2xl">{item}</li>
            ))}
          </ol>
        </div>
      </section>
      <section className="bg-primary text-coffee px-8 min-h-64 py-8">
        <div className="bg-secondary rounded-xl p-4 max-w-4xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Origin of Life Scriber</h2>
          <p className="text-lg md:text-2xl max-w-2xl mx-auto">{origin}</p>
        </div>
      </section>

      {/* <div className="flex flex-col md:flex-row md:space-x-4">
        <div className="flex-1">
          <Book />
        </div>
      </div> */}
    </div>
  );
}
