import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {  collection, query, where, getDocs } from "firebase/firestore";
import { IInvite } from "../../interfaces/interfaces";
import { auth, db } from "../../services/firebase";


const fetchInvites = async (): Promise<IInvite[]> => {
  const invites: IInvite[] = [];
  const currentUser = auth.currentUser;

  if (currentUser) {
    const invitesQuery = query(collection(db, "invites"), where("invitee", "==", currentUser.email));
    const querySnapshot = await getDocs(invitesQuery);
    querySnapshot.forEach((inviteDoc) => {
      invites.push({ id: inviteDoc.id, ...inviteDoc.data() } as IInvite);
    });
  }

  return invites;
};

export const useInvites = (): UseQueryResult<IInvite[], Error> => {
  return useQuery<IInvite[], Error>({
    queryKey: ["invites"],
    queryFn: fetchInvites,
  });
};
