import Login from "./login";

interface NavWithSideBarProps {
  linksDisabled?: boolean;
}
function NavWithSideBar({ linksDisabled }: NavWithSideBarProps) {

  return (
    <>
      <div className="ps-4 py-4 text-white bg-primary font-bold text-2xl flex justify-between">
        <h2 className="min-w-1/2">
          <a className="md:px-8 px-0" href="/">Life Scriber</a>
        </h2>
        <div className="flex flex-row space-x-8 md:mx-16 mx-0 me-4">
          <div className="text-lg font-light">ABOUT</div>
          <Login />
        </div>
      </div>

    </>
  );
}

export default NavWithSideBar;
