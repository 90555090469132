import { useMutation, UseMutationResult } from "@tanstack/react-query";
import axios from "axios";

const sendEmail = async (email: string): Promise<void> => {
  if (location.hostname !== "localhost") {
    try {
      const response = await axios.post(
        `https://us-central1-journal-swap-2ec4b.cloudfunctions.net/sendMail?dest=${email}`,
        {},
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
          }
        }
      );
      console.log("Email sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  } else {
    console.log("Email sending skipped in localhost environment.");
  }
};

export const useSendEmail = (): UseMutationResult<void, Error, string> => {
  return useMutation<void, Error, string>({
    mutationFn: sendEmail,
  });
};
