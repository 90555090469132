export const FONTS = [
    "allcaps",
    "helium",
    // "radith", //too hard to read
    "semisweet",
    "notebook",
    "handwritten",
    "minimalist",
    "simplealphabet",
    // "staycation", //too hard to read
    "moderndesigner",
    "modernhandmade",
    "modernsans",
    "coolkids",
    "daisyviolette",
    "caramel",
    "bigbaby",
    "cupcakes",
    "calligraphy",
]
