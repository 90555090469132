import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { auth, db } from '../../services/firebase';
import { IJournal } from '../../interfaces/interfaces';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

const fetchJournals = async (): Promise<IJournal[]> => {
  
  const currentUser = auth.currentUser;
  
  if (!currentUser) {
    throw new Error('User not authenticated');
  }
  else{
    console.log(currentUser)
  }

  try {
    console.log("FETCH JOURNALS");
    const q = query(collection(db, 'journals'), where("allowedUsers", "array-contains", currentUser.uid));
    const journalsSnapshot = await getDocs(q);
    const journalsList = journalsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as IJournal));

    console.log("journalList", journalsList);
    console.log("FETCH JOURNALS 2");
    return journalsList;
  } catch (error) {
    console.error("Error fetching journals:", error);
    throw new Error("Failed to fetch journals");
  }
};

export const useJournals = (): UseQueryResult<IJournal[], Error> => {
  return useQuery<IJournal[], Error>({
    queryKey: ['journals'],
    queryFn: fetchJournals
  });
};
