import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { IEntry } from "../../interfaces/interfaces";

const updateEntry = async (updatedEntry: IEntry): Promise<IEntry> => {
  const db = getFirestore();
  const entryDoc = doc(db, `journals/${updatedEntry.journalId}/entries/${updatedEntry.id}`);
  await updateDoc(entryDoc, {...updatedEntry});
  return updatedEntry;
};

export const useUpdateEntry = (): UseMutationResult<IEntry, Error, IEntry> => {
  const queryClient = useQueryClient();
  return useMutation<IEntry, Error, IEntry>({
    mutationFn: updateEntry,
    onSuccess: (updatedEntry, variables) => {
      queryClient.invalidateQueries({ queryKey: ['entries', variables.journalId] });
    },
  });
};
