import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { getFirestore, doc, deleteDoc } from "firebase/firestore";
import { IEntry } from "../../interfaces/interfaces";

const deleteEntry = async (entryToDelete: IEntry): Promise<void> => {
  const db = getFirestore();
  const entryDoc = doc(db, `journals/${entryToDelete.journalId}/entries/${entryToDelete.id}`);
  await deleteDoc(entryDoc);
};

export const useDeleteEntry = (): UseMutationResult<void, Error, IEntry> => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, IEntry>({
    mutationFn: deleteEntry,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['entries'] });
    },
  });
};