import { useMutation, useQueryClient, UseMutationResult } from "@tanstack/react-query";
import { getFirestore, doc, deleteDoc } from "firebase/firestore";

const deleteInvite = async (inviteId: string): Promise<void> => {
    const db = getFirestore();
    const inviteDoc = doc(db, "invites", inviteId);
    await deleteDoc(inviteDoc);
  };
export const useDeleteInvite = (): UseMutationResult<void, Error, string> => {
  const queryClient = useQueryClient();
  
  return useMutation<void, Error, string>({
    mutationFn: deleteInvite,
    onSuccess: () => {
      // Invalidate invites query to refresh the list
      queryClient.invalidateQueries({ queryKey: ['invites'] });
    },
  });
};
