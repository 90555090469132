import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { IEntry } from "../../interfaces/interfaces";
import { auth } from '../../services/firebase';  // Adjust the import path as necessary

const fetchEntries = async (journalId: string): Promise<IEntry[]> => {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error('User not authenticated');
  }

  const db = getFirestore();
  const entriesRef = collection(db, `journals/${journalId}/entries`);
  const q = query(entriesRef);
  console.log(currentUser.uid, journalId)
  const querySnapshot = await getDocs(q);
  
  const entries: IEntry[] = [];
  querySnapshot.forEach((doc) => {
    entries.push({ id: doc.id, ...doc.data() } as IEntry);
  });

  return entries;
};

export const useEntries = (journalId: string): UseQueryResult<IEntry[], Error> => {
  return useQuery<IEntry[], Error>({
    queryKey: ['entries', journalId],
    queryFn: () => fetchEntries(journalId),
  });
};
