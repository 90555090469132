import React from "react";
import JoinJournal from "../components/journal/joinJournal";
import NewJournal from "../components/journal/newJournal";

function Invite() {
    return (
        <div>
            <NewJournal />
            <JoinJournal />
        </div>
    )
}

export default Invite